import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { HStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { ArrowRightIcon } from "@chakra-ui/icons";

import { FiltersI } from "../interfaces";
import { getLanguage } from "../helpers";

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  & > * {
    padding: 0.1em 0;
  }

  span {
    cursor: pointer;
  }
`;

const FilterElement = styled.div`
  position: relative;
  padding: 1em 0.5em;
  margin-top: -0.75em;
  margin-bottom: -0.75em;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;

  &:last-of-type {
    margin-right: -0.5em;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 0%;
    display: block;
    height: 1px;
    transition: all 0.3s ease;
    margin-top: 2px;
    transition: all 300ms ease-in;
  }

  &:after {
    bottom: 22%;
    left: 0;
  }

  &:before {
    top: 22%;
    right: 0;
  }

  &.active {
    color: #ffb45c;
    text-shadow: rgb(0 0 0 / 50%) 0 0 2px;
    font-weight: bold;
  }

  &:hover {
    color: #ffb45c;
  }

  &:hover::after,
  &:hover::before {
    width: 100%;
    height: 1px;
    background-color: #000;
  }

  &:hover::after {
    bottom: 22%;
  }
  &:hover::before {
    top: 22%;
  }
`;

const translate = (key: string, locale: string) => {
  if (locale === "en") return key;
  const translations = {
    de: {
      All: "Alle",
      Installation: "Installation",
      Object: "Objekt",
      Painting: "Gemälde",
      Sculpture: "Skulptur",
      "Video Installation": "Videoinstallation",
    },
  };

  return translations[locale][key];
};

const Filters = ({
  locale,
  filterByTag,
  filterByDate,
  allDatesShown,
  setAllDatesShown,
  showDatesNum,
  highlights: {
    highlightedDateIndex,
    setHighlightedDateIndex,
    highlightedTagIndex,
    setHighlightedTagIndex,
  },
}: {
  locale: string;
  filterByTag?: (tag: string) => void;
  filterByDate?: (date: string) => void;
  allDatesShown: boolean;
  setAllDatesShown: (value: boolean) => void;
  showDatesNum: number;
  highlights: {
    highlightedDateIndex: number;
    setHighlightedDateIndex: (value: number) => void;
    highlightedTagIndex: number;
    setHighlightedTagIndex: (value: number) => void;
  };
}) => {
  const {
    allContentfulWork: { dates, tags },
  }: { allContentfulWork: FiltersI } = useStaticQuery(graphql`
    query Filters {
      allContentfulWork {
        dates: distinct(field: { date: SELECT })
        tags: distinct(field: { tags: SELECT })
      }
    }
  `);

  return (
    <FiltersWrapper>
      <HStack spacing={4}>
        {[
          getLanguage(locale) === "de" ? "Alle" : "All",
          ...[
            ...new Set(
              [...dates.sort((a, b) => dayjs(b).diff(dayjs(a)))].map((date) =>
                dayjs(date).format("YYYY")
              )
            ),
          ],
        ].map((date, i) => {
          if (i - 1 === showDatesNum && !allDatesShown)
            return (
              <FilterElement onClick={() => setAllDatesShown(true)} key="more">
                <ArrowRightIcon />
              </FilterElement>
            );

          if (i - 1 > showDatesNum && !allDatesShown) return null;

          return (
            <FilterElement
              key={date}
              className={highlightedDateIndex === i ? "active" : ""}
              onClick={() => {
                if (typeof filterByDate === "function") {
                  filterByDate(
                    date === "Alle" || date === "All"
                      ? date
                      : dayjs(date).format("YYYY")
                  );
                  setHighlightedDateIndex(i);
                }
              }}
            >
              {i !== 0 ? dayjs(date).format("YYYY") : date}
            </FilterElement>
          );
        })}
      </HStack>
      <HStack spacing={4}>
        {["All", ...tags.sort((a, b) => a.localeCompare(b))].map((tag, i) => (
          <FilterElement
            key={tag}
            className={highlightedTagIndex === i ? "active" : ""}
            onClick={() => {
              if (typeof filterByTag === "function") {
                filterByTag(tag.toLowerCase());
                setHighlightedTagIndex(i);
              }
            }}
          >
            {translate(tag, locale)}
          </FilterElement>
        ))}
      </HStack>
    </FiltersWrapper>
  );
};

export default Filters;
