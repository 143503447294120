import * as React from "react";
import { graphql } from "gatsby";
import { Button, Flex, GridItem, Heading, SimpleGrid } from "@chakra-ui/react";
import dayjs from "dayjs";

import Filters from "../components/Filters";
import Layout from "../components/Layout";
import WorkCard from "../components/WorkCard";

import { WorkI } from "../interfaces";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const Works = ({
  data: {
    allContentfulWork: { nodes },
  },
  pageContext: { title, description, language, localeSwitcher },
  location,
}: {
  data: { allContentfulWork: { nodes: WorkI[] } };
  pageContext: {
    title: string;
    description: string;
    language: string;
    localeSwitcher: { [key: string]: string };
  };
  location: { pathname: string };
}) => {
  const [filteredData, setFilteredData] = React.useState(nodes);
  const [userFilters, setUserFilters] = React.useState({
    date: "All",
    tag: "Object", // tag: "All",
  });

  const showDatesNum = 4;
  const [allDatesShown, setAllDatesShown] = React.useState(false);

  const [highlightedDateIndex, setHighlightedDateIndex] = React.useState(0);
  const [highlightedTagIndex, setHighlightedTagIndex] = React.useState(2);

  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    filterData();
  }, [userFilters, page]);

  const filterByTag = (_tag: string): void => {
    setUserFilters({ ...userFilters, tag: _tag });
  };

  const filterByDate = (_date: string): void => {
    setUserFilters({ ...userFilters, date: _date });
  };

  const filterData = () => {
    const _date = userFilters.date.toLowerCase();
    const _tag = userFilters.tag.toLowerCase();

    const filteredByDate = nodes.filter(({ date }) => {
      if (_date === "all" || _date === "alle") return true;
      return _date && dayjs(date).format("YYYY") === _date;
    });

    const filteredByTag = filteredByDate.filter(({ tags }) => {
      if (_tag === "all" || _tag === "alle") return true;
      return tags && tags.map((tag) => tag.toLowerCase()).includes(_tag);
    });
    setFilteredData(filteredByTag);
  };

  return (
    <Layout
      title={title}
      description={description}
      locale={language}
      location={location}
      limitWidth
      paddingTop="6.5rem"
      localeSwitcher={localeSwitcher}
    >
      <Flex
        justify="space-between"
        align="end"
        style={{ marginBottom: "2rem" }}
      >
        <Heading as="h2">{title}</Heading>
        <Filters
          locale={language}
          filterByDate={filterByDate}
          filterByTag={filterByTag}
          allDatesShown={allDatesShown}
          setAllDatesShown={setAllDatesShown}
          showDatesNum={showDatesNum}
          highlights={{
            highlightedDateIndex,
            setHighlightedDateIndex,
            highlightedTagIndex,
            setHighlightedTagIndex,
          }}
        />
      </Flex>
      {filteredData?.length ? (
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
          <Masonry gutter="1rem">
            {filteredData?.map((data, index) => (
              <WorkCard data={data} locale={language} key={index} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      ) : (
        // <SimpleGrid columns={[1, 2]} spacing={4}>
        //   {filteredData?.map((data, index) => (
        //     <GridItem key={index}>
        //       <WorkCard data={data} locale={language} key={index} />
        //     </GridItem>
        //   ))}
        // </SimpleGrid>
        <>
          {language === "de" ? "Keine Werke gefunden" : "No works found"}
          <br />
          <br />
          <Button
            onClick={() => {
              setUserFilters({
                date: "All",
                tag: "All",
              });
              filterData();
              setHighlightedDateIndex(0);
              setHighlightedTagIndex(0);
            }}
          >
            {language === "de" ? "Filter zurücksetzen" : "Reset filters"}
          </Button>
        </>
      )}
    </Layout>
  );
};

export default Works;

export const pageQuery = graphql`
  query WorksQuery($language: String!) {
    allContentfulWork(
      filter: { node_locale: { eq: $language }, title: { ne: "DummyWork" } }
      sort: { date: DESC }
    ) {
      nodes {
        ...ContentfulWorkFragment
      }
    }
  }
`;
